<header>
    <div class="header-top-area">
      <div class="container">
        <div class="mobile-nav" (click)="clickEvent()" [ngClass]="status ? 'active' : ''">
          <span></span>
        </div>
        <a class="logo" routerLink="/" >
          <img src="../../../assets/images/logo.png" alt="logo">
        </a>
        <div class="topbar-contact-info">
          <div class="contact-info-item">
            <div class="icon message"></div>
            <div class="text-item">
              {{"HOME.WRITE_US"| translate }}<br>
              {{contactList[0]?.email}}
            </div>
          </div><!--.contact-info-item-->
          <div class="contact-info-item">
            <div class="icon phone"></div>
            <div class="text-item">
              {{"HOME.CALL_US"| translate }}<br>
              {{contactList[0]?.tel_1}}
            </div>
          </div><!--.contact-info-item-->
          <div class="contact-info-item">
            <div class="icon phone"></div>
            <div class="text-item">
              {{"HOME.CALL_US"| translate }}<br>
              {{contactList[0]?.tel_2}}
            </div>
          </div><!--.contact-info-item-->
          <div class="contact-info-item">
            <div class="icon phone"></div>
            <div class="text-item">
              {{"HOME.CALL_US"| translate }}<br>
              {{contactList[0]?.tel_3}}
            </div>
          </div><!--.contact-info-item-->
        </div><!--.topbar-contact-info-->
      </div><!--.container-->
    </div><!--.header-top-area-->
    <div class="header-bottom-area" [ngClass]="status ? 'mobile-active' : ''">
      <div class="container">
        <a class="header-scrolled_logo" routerLink="/" >
          <img src="../../../assets/images/logo-stpo-white.png" alt="logo">
        </a>
        <nav>
          <a routerLink= "{{'PATH.HOME_PATH'| translate}}" class="nav-item">{{'HOME.HOME' | translate}}</a>
          <a routerLink="{{'PATH.ABOUT_PATH'| translate}}" class="nav-item">{{'HOME.ABOUT' | translate}}</a>
          <a routerLink="{{'PATH.SERVICE_PATH'| translate}}" class="nav-item">{{'HOME.SERVICES' | translate}}</a>
          <!-- <a class="nav-item" (click)="toggleBrochureDropdown()">
            {{'HOME.BROCHURES' | translate }}
          </a> -->
          <div class="select-catalogue">
            <ng-select [class]="'lang-select select-lang-header'" [items]="catalogOptions" bindLabel="name" bindValue="value"  placeholder=  "{{'HOME.BROCHURES' | translate }}" [clearable]="false">
              <ng-template ng-label-tmp let-item="item">
                {{'HOME.BROCHURES' | translate }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" *ngIf="catalogOptions.length > 0">
                <span (click)="showPdf(item.file)">{{item.nom}}</span>
              </ng-template>
            </ng-select>
         </div>
          <!--<a class="nav-item">{{'HOME.CERTIFICATE' | translate}}</a>-->
          <a routerLink="{{'PATH.CONTACT_PATH'| translate}}" class="nav-item">{{'HOME.CONTACT' | translate}}</a>
        </nav>
        <div class="select-lang">
          <ng-select [class]="'lang-select select-lang-header'" [items]="selectItem" (change)="useLanguage($event)"  bindLabel="name" bindValue="value"  placeholder="Select Lang" [(ngModel)]="currentLang" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
              <span>{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span>{{item.name}}</span>
            </ng-template>
          </ng-select>
        </div>
      </div><!--.container-->
    </div><!--.header-bottom-area-->
</header>
