import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './vitrine/page-not-found/page-not-found.component';

const routes: Routes = [
   {
    path: '',
    loadChildren: () => import('./vitrine/vitrine.module').then(m => m.VitrineModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./backadmin/backadmin.module').then(m => m.BackadminModule),
  },
  { path: '**', redirectTo: '/404/', pathMatch: 'full' },
  { path: '404/', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
